import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './Phone.module.sass'

const Phone = () => {

  const { t } = useTranslation()

  return (
    <div className={ styles['phone-frame'] }>
      <div className={ styles['phone-image-frame'] }>
        <img
          src="top/phone-large.png"
          width={ 320 }
          height={ 636 }
          className={ styles['phone-image'] }
          alt="Phone App"
        />
      </div>
      <div className={ styles['right-frame']}>
        <div className={ styles.bar }></div>
        <div className={ styles['text-frame'] }>
          <div className={ styles.block }>
            <div className={ styles.title }>{t('Passion Shotsのアップロード')}</div>
            <div className={ styles.text }>{t('Passion Shotsのアップロード説明')}</div>
          </div>
          <div className={ styles.block }>
            <div className={ styles.title }>{t('Paikersマップ')}</div>
            <div className={ styles.text }>{t('Paikersマップ説明')}</div>
          </div>
          <div className={ styles.block }>
            <div className={ styles.title }>{t('Paikersメッセージ')}</div>
            <div className={ styles.text }>{t('Paikersメッセージ説明')}</div>
          </div>
          <div className={ styles.block }>
            <div className={ styles.title }>{t('Paikerを検索')}</div>
            <div className={ styles.text }>{t('Paikerを検索説明')}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Phone
