import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PaikeHeader from '../molecules/PaikeHeader'
import PaikeFooter from '../molecules/PaikeFooter'
import styles from './TermsOfService.module.sass'

const TermsOfService = () => {

  const { i18n, t } = useTranslation()
  const { language } = useParams()
  const currentLanguage = i18n.resolvedLanguage

  useEffect(() => {
    if (i18n && language && (language !== currentLanguage)) {
      i18n.changeLanguage(language)
    }
  }, [ i18n, language, currentLanguage ])

  return (
    <div>
      <PaikeHeader />
      <div className={ styles.frame }>
        <h1>{t('t-0')}</h1>
        <p>{t('t-0-1')}</p>
        <p>{t('t-1')}</p>
        <p>{t('t-1-1')}</p>
        <p>{t('t-2')}</p>
        <ol>
          <li>{t('t-2-1')}</li>
          <li>{t('t-2-2')}</li>
        </ol>
        <p>{t('t-3')}</p>
        <ol>
          <li>
            {t('t-3-1')}
            <ol>
              <li>{t('t-3-1-1')}</li>
              <li>{t('t-3-1-2')}</li>
              <li>{t('t-3-1-3')}</li>
              <li>{t('t-3-1-4')}</li>
              <li>{t('t-3-1-5')}</li>
              <li>{t('t-3-1-6')}</li>
            </ol>
          </li>
          <li>{t('t-3-2')}</li>
          <li>{t('t-3-3')}</li>
          <li>{t('t-3-4')}</li>
          <li>{t('t-3-5')}</li>
          <li>{t('t-3-6')}</li>
        </ol>
        <p>{t('t-4')}</p>
        <ol>
          <li>
            {t('t-4-1')}
            <ol>
              <li>{t('t-4-1-1')}</li>
              <li>{t('t-4-1-2')}</li>
            </ol>
          </li>
          <li>{t('t-4-2')}</li>
          {t('t-4-3') && (
            <li>{t('t-4-3')}</li>
          )}
        </ol>
        <p>{t('t-5')}</p>
        <ol>
          <li>{t('t-5-1')}</li>
          <li>{t('t-5-2')}</li>
        </ol>
        <p>{t('t-6')}</p>
        <p>{t('t-6-1')}</p>
        <p>{t('t-7')}</p>
        <p>{t('t-7-1')}</p>
        <p>{t('t-8')}</p>
        <ol>
          <li>{t('t-8-1')}</li>
          <li>{t('t-8-2')}</li>
          <li>{t('t-8-3')}</li>
          <li>{t('t-8-4')}</li>
          <li>{t('t-8-5')}</li>
        </ol>
        <p>{t('t-9')}</p>
        <ol>
          <li>
            {t('t-9-1')}
            <ol>
              <li>{t('t-9-1-1')}</li>
              <li>{t('t-9-1-2')}</li>
              <li>{t('t-9-1-3')}</li>
              <li>{t('t-9-1-4')}</li>
              <li>{t('t-9-1-5')}</li>
              <li>{t('t-9-1-6')}</li>
              <li>{t('t-9-1-7')}</li>
              <li>{t('t-9-1-8')}</li>
              <li>{t('t-9-1-92')}</li>
              <li>{t('t-9-1-10')}</li>
              <li>{t('t-9-1-11')}</li>
              <li>{t('t-9-1-12')}</li>
              <li>{t('t-9-1-13')}</li>
              <li>{t('t-9-1-14')}</li>
              <li>{t('t-9-1-15')}</li>
              <li>{t('t-9-1-16')}</li>
            </ol>
          </li>
          <li>{t('t-9-2')}</li>
          <li>{t('t-9-3')}</li>
        </ol>
        <p>{t('t-10')}</p>
        <ol>
          <li>{t('t-10-1')}</li>
          <li>{t('t-10-2')}</li>
          <li>{t('t-10-3')}</li>
          <li>{t('t-10-4')}</li>
          <li>{t('t-10-5')}</li>
        </ol>
        <p>{t('t-11')}</p>
        <ol>
          <li>
            {t('t-11-1')}
            <ol>
              <li>{t('t-11-1-1')}</li>
              <li>{t('t-11-1-2')}</li>
              <li>{t('t-11-1-3')}</li>
              <li>{t('t-11-1-4')}</li>
              <li>{t('t-11-1-5')}</li>
              <li>{t('t-11-1-6')}</li>
            </ol>
          </li>
          <li>{t('t-11-2')}</li>
        </ol>
        <p>{t('t-12')}</p>
        <p>{t('t-12-1')}</p>
        <p>{t('t-13')}</p>
        <p>{t('t-13-1')}</p>
        <p>{t('t-14')}</p>
        <ol>
          <li>{t('t-14-1')}</li>
          <li>{t('t-14-2')}</li>
        </ol>
        <div className={ styles.right }>
          <p>{t('t-e')}</p>
          <p>{t('t-date-of')}</p>
          <p>{t('t-date')}</p>
        </div>
        <p>{t('t-date-j')}</p>
      </div>
      <PaikeFooter />
    </div>
  )
}
export default TermsOfService
