import AppFooter from '../molecules/AppFooter'

const LikeList = () => {
  return (
    <div>
      LikeList
      <AppFooter />
    </div>
  )
}
export default LikeList

