import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styles from './PaikeFooter.module.sass'

const PaikeFooter = () => {

  const navigate = useNavigate()
  const { t } = useTranslation()

  const openTop = () => {
    navigate('/')
  }
  const openFacebook = () => {
    window.open('https://www.facebook.com/paikeout/', '_blank')
  }
  const openInstagram = () => {
    window.open('https://www.instagram.com/paikeout/', '_blank')
  }
  const openTwitter = () => {
    window.open('https://twitter.com/paikeout', '_blank')
  }
  const openFaq = () => {
    navigate('/faq')
  }
  const openAboutUs = () => {
    navigate('/aboutUs')
  }
  const openContact = () => {
    window.open('mailto:support@paikeout.com', '_blank')
  }
  const openTos = () => {
    navigate('/tos')
  }
  const openPrivacy = () => {
    navigate('/privacy')
  }
  const openBlogJa = () => {
    window.open('https://paikeout.com/blog/ja/', '_blank')
  }
  const openBlogEn = () => {
    window.open('https://paikeout.com/blog/en/', '_blank')
  }
  return (
    <div className={ styles.frame }>
      <div className={ styles.upper }>
        <div className={ styles['paike-logo'] } onClick={ openTop }>
          <img src="/common/logo.svg" width={ 160 } height={ 70 } alt="Paike Logo" />
        </div>
        <div className={ styles['sns-icon-frame'] }>
          <SnsIcon src="/common/facebook.svg" onClick={ openFacebook } />
          <SnsIcon src="/common/instagram.svg" onClick={ openInstagram } />
          <SnsIcon src="/common/twitter.svg" onClick={ openTwitter } />
        </div>
      </div>
      <div className={ styles['link-frame'] }>
        <Link text="FAQ" onClick={openFaq} />
        <Link text="AboutUs" onClick={openAboutUs} />
        <Link text="Contact" onClick={openContact} />
        <Link text="Terms of Service" onClick={openTos} />
        <Link text="Privacy Policy" onClick={openPrivacy} />
        <Link text={t('Blog(日本語)')} onClick={openBlogJa} />
        <Link text={t('Blog(英語)')} onClick={openBlogEn} />
      </div>
    </div>
  )
}
export default PaikeFooter

type SnsIconProps = {
  src: string,
  onClick: () => void,
}
const SnsIcon = (props: SnsIconProps) => {
  return (
    <div className={ styles['sns-icon'] } onClick={ props.onClick }>
      <img src={ props.src } width={ 24 } height={ 24 } alt="Sns Logo" />
    </div>
  )
}

type LinkProps = {
  text: string,
  onClick: () => void,
}
const Link = (props: LinkProps) => {
  return (
    <div className={ styles['link'] } onClick={ props.onClick }>
      { props.text }
    </div>
  )
}
