import React from 'react'
import classnames from 'classnames'
import styles from './Bubble.module.sass'

interface Props {
  className: string,
}

interface BubbleProps extends Props {
  src: string,
  duration: number,
}

const Bubble = ({ className, duration, src }: BubbleProps) => {
  return (
    <div
      className={ classnames(styles.bubble, className) } style={{
      animationDuration: `${duration}s`,
    }}>
      <img src={ src } alt="hover item" />
    </div>
  )
}

export const Bike = (props: Props) => {
  return <Bubble src="/top/bubble-bike.svg" duration={ 1 } { ...props } />
}

export const Favorite = (props: Props) => {
  return <Bubble src="/top/bubble-favorite.svg" duration={ 1.5 } { ...props } />
}

export const Football = (props: Props) => {
  return <Bubble src="/top/bubble-football.svg" duration={ 1.3 } { ...props } />
}
