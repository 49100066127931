import React from 'react'

export const AppStore = () => {
  const openAppStore = () => {
    window.open('https://apps.apple.com/app/paike-passion-like/id1539421003', '_blank')
  }
  return (
    <img
      src="/common/app-store.svg"
      onClick={ openAppStore }
      alt="App Store"
    />
  )
}

export const GooglePlay = () => {
  const openGooglePlay = () => {
    window.open('https://play.google.com/store/apps/details?id=com.paikeout.app', '_blank')
  }
  return (
    <img
      src="/common/google-play.svg"
      onClick={ openGooglePlay }
      alt="Google Play"
    />
  )
}
