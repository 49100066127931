import { useTranslation } from 'react-i18next'
import PaikeHeader from '../../molecules/PaikeHeader'
import PaikeFooter from '../../molecules/PaikeFooter'
import Top from './Top'
import Phone from './Phone'
import Voice from './Voice'
import styles from './Index.module.sass'

const Index = () => {

  const { t } = useTranslation()

  return (
    <div className={ styles.frame }>
      <PaikeHeader />
      <Top />
      <Phone />
      <div className={ styles['map-frame'] }>
        <div className={ styles.title }>
          {t('Paikeの紹介')}
        </div>
        <video className={ styles.video } muted autoPlay loop playsInline>
          <source src="/top/promo.mp4" type="video/mp4" />
        </video>
      </div>
      <Voice />
      <PaikeFooter />
    </div>
  )
}
export default Index
