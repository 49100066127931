import { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PaikeHeader from '../molecules/PaikeHeader'
import PaikeFooter from '../molecules/PaikeFooter'
import ja from '../../resources/ja.json'
import styles from './Faq.module.sass'

const Faq = () => {

  const { i18n, t } = useTranslation()
  const { language } = useParams()
  const currentLanguage = i18n.resolvedLanguage

  useEffect(() => {
    if (i18n && language && (language !== currentLanguage)) {
      i18n.changeLanguage(language)
    }
  }, [ i18n, language, currentLanguage ])

  const keys = Object.keys(ja)
    .filter(k => k.startsWith('f-'))
    .filter(k => k !== 'f-0')
    .sort()

  const list = []
  let oldKey = ''
  keys.forEach(key => {
    if (key.length === 3) {
      oldKey = key
      list.push({
        title: key,
        list: [],
      })
    } else if (key.startsWith(oldKey) && key.endsWith('q')) {
      list[list.length - 1].list.push(key.substring(0, key.length - 2))
    }
  })

  return (
    <div>
      <PaikeHeader />
      <div className={ styles.frame }>
        <h1>{t('f-0')}</h1>
        {list.map(item => (
          <div key={item.title}>
            <h1>{t(item.title)}</h1>
            {item.list.map(i => (
              <Qa key={i} item={i} />
            ))}
          </div>
        ))}
      </div>
      <PaikeFooter />
    </div>
  )
}
export default Faq

const Qa = props => {
  const { t } = useTranslation()
  const [ isShow, setIsShow ] = useState(true)
  const doClick = useCallback(() => {
    setIsShow(isShow => !isShow)
  }, [])
  return (
    <div className={ styles.qa }>
      <div className={ styles.q } onClick={ doClick}>{t(`${props.item}-q`)}</div>
      {isShow && (
        <div className={ styles.a }>{t(`${props.item}-a`)}</div>
      )}
    </div>
  )
}
