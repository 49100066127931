import React, { useRef, Suspense } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { useTexture } from "@react-three/drei"

const Box = () => {
  const texture = useTexture('/top/paike-map.png') //undefined// useLoader(TextureLoader, '/top/paike-map.png')
  // This reference will give us direct access to the mesh
  const mesh = useRef()
  // Subscribe this component to the render-loop, rotate the mesh every framef
  useFrame(() => (mesh.current.rotation.y += 0.002))
  // Return view, these are regular three.js elements expressed in JSX
  return (
    <mesh
      ref={mesh}
      scale={ 1 }
      position={[0, 0, 3.3]}
    >
      <sphereGeometry args={[1, 100, 100]} />
      <meshBasicMaterial map={texture} />
    </mesh>
  )
}

const Earth = props => {
  return (
    <Canvas width={ 464 } height={ 464 }>
      <Suspense fallback={ null }>
        <ambientLight color={0x333333}/>
        <directionalLight color={0xffffff} intensity={1} />
        <Box />
      </Suspense>
    </Canvas>
  )
}

export default Earth
