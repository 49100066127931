import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PaikeHeader from '../molecules/PaikeHeader'
import PaikeFooter from '../molecules/PaikeFooter'
import styles from './AboutUs.module.sass'

const AboutUs = () => {

  const { i18n, t } = useTranslation()
  const { language } = useParams()
  const currentLanguage = i18n.resolvedLanguage

  useEffect(() => {
    if (i18n && language && (language !== currentLanguage)) {
      i18n.changeLanguage(language)
    }
  }, [ i18n, language, currentLanguage ])

  return (
    <div>
      <PaikeHeader />
      <div className={ styles.frame }>
        <h1>{t('a-0-0')}</h1>
        <p>{t('a-0-1')}</p>
        <ul>
          <li>{t('a-0-2')}</li>
          <li>{t('a-0-3')}</li>
        </ul>
        <small>{t('a-0-4')}</small>
        <small>{t('a-0-5')}</small>
        <small>{t('a-0-6')}</small>
        <p>{t('a-0-7')}</p>
        <p></p>
        <h2>{t('a-1-0')}</h2>
        <p>{t('a-1-1')}</p>
        <p>{t('a-1-2')}</p>
        <p>{t('a-1-3')}</p>
        <p></p>
        <h2>{t('a-2-0')}</h2>
        <p>{t('a-2-1')}</p>
        <p>{t('a-2-2')}</p>
        <p></p>
        <p>{t('a-2-11')}</p>
        <p>{t('a-2-12')}</p>
        <p>{t('a-2-13')}</p>
        <p>{t('a-2-14')}</p>
        <p>{t('a-2-15')}</p>
        <p></p>
        <small>{t('a-2-21')}</small>
        <small>{t('a-2-22')}</small>
        <small>{t('a-2-23')}</small>
        <small>{t('a-2-24')}</small>
        <p></p>
        <small>{t('a-2-31')}</small>
        <p></p>
        <h1>{t('a-10-0')}</h1>
        <h2>{t('a-11-1')}</h2>
        <p>{t('a-11-2')}</p>
        <p>{t('a-11-3')}</p>
        <h2>{t('a-12-1')}</h2>
        <p>{t('a-12-2')}</p>
        <h2>{t('a-13-1')}</h2>
        <p>{t('a-13-2')}</p>
        <h2>{t('a-14-1')}</h2>
        <p>{t('a-14-2')}</p>
        <h2>{t('a-15-1')}</h2>
        <p>{t('a-15-2')}</p>
        <h2>{t('a-16-1')}</h2>
        <p><a href="mailto:support@paikeout.com">support@paikeout.com</a></p>
      </div>
      <PaikeFooter />
    </div>
  )
}
export default AboutUs
