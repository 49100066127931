import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PaikeHeader from '../molecules/PaikeHeader'
import PaikeFooter from '../molecules/PaikeFooter'
import styles from './PrivacyPolicy.module.sass'
import { t } from 'i18next'

const PrivacyPolicy = () => {

  const { i18n } = useTranslation()
  const { language } = useParams()
  const currentLanguage = i18n.resolvedLanguage

  useEffect(() => {
    if (i18n && language && (language !== currentLanguage)) {
      i18n.changeLanguage(language)
    }
  }, [ i18n, language, currentLanguage ])


  return (
    <div>
      <PaikeHeader />
      <div className={ styles.frame }>
        <h1>{t('p-0')}</h1>
        <p>{t('p-0-0')}</p>
        <p>{t('p-0-1')}</p>
        <p>{t('p-1')}</p>
        <p>{t('p-1-0-1')}</p>
        <p>{t('p-1-0-2')}</p>
        <p>{t('p-1-1')}</p>
        <p>{t('p-1-1-0')}</p>
        <ul>
          <li>{t('p-1-1-1-1')}</li>
          <li>{t('p-1-1-1-2')}</li>
          <li>{t('p-1-1-1-3')}</li>
          <li>{t('p-1-1-1-4')}</li>
          <li>{t('p-1-1-1-5')}</li>
        </ul>
        <p>{t('p-1-2')}</p>
        <p>{t('p-1-2-0')}</p>
        <ul>
          <li>{t('p-1-2-1-1')}</li>
          <li>{t('p-1-2-1-2')}</li>
        </ul>
        <p>{t('p-1-3')}</p>
        <p>{t('p-1-3-0')}</p>
        <ul>
          <li>{t('p-1-3-1-1')}</li>
          <li>{t('p-1-3-1-2')}</li>
          <li>{t('p-1-3-1-3')}</li>
          <li>{t('p-1-3-1-4')}</li>
          <li>{t('p-1-3-1-5')}</li>
          <li>{t('p-1-3-1-6')}</li>
        </ul>
        <p>{t('p-2')}</p>
        <p>{t('p-2-0')}</p>
        <p>{t('p-2-1')}</p>
        <ul>
          <li>{t('p-2-1-1-1')}</li>
          <li>{t('p-2-1-1-2')}</li>
          <li>{t('p-2-1-1-3')}</li>
          <li>{t('p-2-1-1-4')}</li>
          <li>{t('p-2-1-1-5')}</li>
          <li>{t('p-2-1-1-6')}</li>
          <li>{t('p-2-1-1-7')}</li>
          <li>{t('p-2-1-1-8')}</li>
          <li>{t('p-2-1-1-9')}</li>
          <li>{t('p-2-1-1-10')}</li>
          <li>{t('p-2-1-1-11')}</li>
          <li>{t('p-2-1-1-12')}</li>
          <li>{t('p-2-1-1-13')}</li>
        </ul>
        <p>{t('p-2-2')}</p>
        <p>{t('p-2-2-0')}</p>
        <ul>
          <li>{t('p-2-2-1-1')}</li>
          <li>{t('p-2-2-1-2')}</li>
          <li>{t('p-2-2-1-3')}</li>
          <li>{t('p-2-2-1-4')}</li>
          <li>{t('p-2-2-1-5')}</li>
          <li>{t('p-2-2-1-6')}</li>
          <li>{t('p-2-2-1-7')}</li>
          <li>{t('p-2-2-1-8')}</li>
          <li>{t('p-2-2-1-9')}</li>
          <li>{t('p-2-2-1-10')}</li>
          <li>{t('p-2-2-1-11')}</li>
          <li>{t('p-2-2-1-12')}</li>
          <li>{t('p-2-2-1-13')}</li>
        </ul>
        <p>{t('p-3')}</p>
        <p>{t('p-3-0')}</p>
        <p>{t('p-4')}</p>
        <p>{t('p-4-0-0')}</p>
        <p>{t('p-4-0-1')}</p>
        <p>{t('p-5')}</p>
        <p>{t('p-5-0')}</p>
        <p>{t('p-5-1')}</p>
        <p>{t('p-5-2')}</p>
        <p>{t('p-5-3')}</p>
        <p>{t('p-5-4')}</p>
        <p>{t('p-6')}</p>
        <p>{t('p-6-0')}</p>
        <p>{t('p-7')}</p>
        <p>{t('p-7-0')}</p>
        <p>{t('p-8')}</p>
        <p>{t('p-8-1')}</p>
        <p>{t('p-8-1-0')}</p>
        <p>{t('p-8-2')}</p>
        <p>{t('p-8-2-0')}</p>
        <p>{t('p-8-3')}</p>
        <p>{t('p-8-3-0')}</p>
        <p>{t('p-9')}</p>
        <p>{t('p-9-0')}</p>
        <div className={ styles.address }>
          <p>{t('p-9-0-0')}</p>
          <p>{t('p-9-0-1')}</p>
          <p>{t('p-9-0-2')}<a href="mailto:support@paikeout.com">support@paikeout.com</a></p>
        </div>
        <p>{t('p-10')}</p>
        <p>{t('p-10-0')}</p>
        <div className={ styles.right }>
          <p>{t('t-e')}</p>
        </div>
        <p>{t('t-date-j')}</p>
      </div>
      <PaikeFooter />
    </div>
  )
}
export default PrivacyPolicy
