import './i18n'
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Index from './components/pages/index/Index'
import Faq from './components/pages/Faq'
import AboutUs from './components/pages/AboutUs'
import TermsOfService from './components/pages/TermsOfService'
import PrivacyPolicy from './components/pages/PrivacyPolicy'
import LikeList from './components/pages/LikeList'
import ScrollToTop from './components/atoms/ScrollToTop'

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/faq" element={ <Faq />} />
          <Route path="/:language/faq" element={ <Faq />} />
          <Route path="/aboutUs" element={ <AboutUs />} />
          <Route path="/:language/aboutUs" element={ <AboutUs />} />
          <Route path="/tos" element={ <TermsOfService />} />
          <Route path="/:language//tos" element={ <TermsOfService />} />
          <Route path="/privacy" element={ <PrivacyPolicy />} />
          <Route path="/:language//privacy" element={ <PrivacyPolicy />} />
          <Route path="/like-list" element={ <LikeList />} />
          <Route path="/*" element={ <Index />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
