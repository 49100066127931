import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as Button from '../../atoms/Button'
import Earth from '../../molecules/Earth'
import * as Bubble from '../../molecules/Bubble'
import styles from './Top.module.sass'

const Top = () => {

  const { t } = useTranslation()
  const navigate = useNavigate()

  const moveWebApp = () => {
    navigate('/like-list')
  }

  return (
    <div className={ styles['top-frame'] }>
      <div className={ styles.left }>
        <div className={ styles.title }>
          {t('好きで世界と繋がろう')}
        </div>
        <div className={ styles.subtitle }>
          Paike = Passion×Like
        </div>
        <div className={ styles.contents }>
          {t('好きを投稿しよう')}
        </div>
        <div className={ styles['store-frame'] }>
          <Button.AppStore />
          <Button.GooglePlay />
        </div>
        <button onClick={ moveWebApp }>
          Let&#39;s explore a bit on the Web!
        </button>
      </div>
      <div className={ styles.right }>
        <div className={ styles.earth }>
          <Earth />
          <Bubble.Bike className={ styles.bike } />
          <Bubble.Football className={ styles['foot-ball'] } />
          <Bubble.Favorite className={ styles.favorite} />
        </div>
      </div>
    </div>
  )
}
export default Top
