import React from 'react'
import styles from './AppFooter.module.sass'

const AppFooter = () => {
  return (
    <div className={ styles['button-area'] }>
      <Button text="Explore" icon="/icons/search.svg" />
      <Button text="Bookmark" icon="/icons/bookmark.svg" />
      <Button text="Shots" icon="/icons/camera.svg" />
      <Button text="Message" icon="/icons/message.svg" />
      <Button text="Account" icon="/icons/account.svg" />
      <div className={ styles['lock-frame'] }>
        <img src="/icons/lock.svg" alt="Lock" />
      </div>
    </div>
  )
}
export default AppFooter

type ButtonProps = {
  icon: string,
  text: string,
}
const Button = ({ icon, text }: ButtonProps) => {
  return (
    <div className={ styles.button }>
      <img src={ icon } className={ styles.icon } alt="Button" />
      <div className={ styles.text }>{ text }</div>
    </div>
  )
}
