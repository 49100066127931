import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styles from './PaikeHeader.module.sass'

const PaikeHeader = () => {

  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const language = i18n.resolvedLanguage
  const [ showLanguage, setShowLanguage ] = useState(false)

  const openTop = () => {
    navigate('/')
  }

  const clickLanguage = () => {
    setShowLanguage(true)
  }

  const toEnglish = () => {
    if (language !== 'en') {
      i18n.changeLanguage('en')
    }
    setShowLanguage(false)
  }

  const toJapanese = () => {
    if (language !== 'ja') {
      i18n.changeLanguage('ja')
    }
    setShowLanguage(false)
  }

  return (
    <div className={ styles.frame }>
      <div className={ styles['paike-logo'] } onClick={ openTop }>
        <img src="/common/logo.svg" width={ 60 } height={ 26 } alt="Paike Logo" />
      </div>
      <div className={ styles['language-button'] }>
        {language === 'ja' ? (
          <span onClick={ clickLanguage }>English</span>
        ) : (
          <span onClick={ clickLanguage }>日本語</span>
        )}
        { showLanguage && (
          <div className={ styles['language-frame'] }>
            <div className={ styles.button } onClick={ toEnglish }>English</div>
            <div className={ styles.button } onClick={ toJapanese }>日本語</div>
          </div>
        )}
      </div>
    </div>
  )
}
export default PaikeHeader
