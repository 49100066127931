import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './Voice.module.sass'
import Card from './Card'

const Voice = () => {

  const { t } = useTranslation()

  return (
    <div className={ styles['voice-frame'] }>
      <div className={ styles.title }>
        Paiker&#39;s Voice
      </div>
      <div className={ styles['card-frame']}>
        <Card
          src="/top/okonomiyaki-large.png"
          title={t('お好み焼き')}
          location={t('お好み焼き場所')}
        >
          {t('お好み焼き説明')}
        </Card>
        <Card
          src="/top/karaoke-large.png"
          title={t('カラオケ')}
          location={t('カラオケ場所')}
        >
          {t('カラオケ説明')}
        </Card>
      </div>
    </div>
  )
}
export default Voice
