import React from 'react'
import styles from './Card.module.sass'

type Props = {
  src: string,
  title: string,
  location: string,
  children: React.ReactNode,
}
const Card = ({ src, title, location, children }: Props) => {
  return (
    <div className={ styles.card }>
      <img
        src={ src }
        className={ styles.image }
        width={ 562 }
        height={ 270 }
        alt="Card"
      />
      <div className={ styles.text }>
        <div className={ styles.title }>{ title }</div>
        <div className={ styles.contents }>
          { children }
        </div>
        <div className={ styles.location }>
          <img
            src="top/location.svg"
            className={ styles['location-icon'] }
            alt="Location"
          />
          <div>{ location }</div>
        </div>
      </div>
    </div>
  )
}
export default Card
